<template>
  <div class="gallery-section" v-if="slider.length">
    <v-row>
      <v-col>
        <div class="heading">
          WizFit Gallery
          <div class="hLine"></div>
        </div>
      </v-col>
    </v-row>
    <v-container class="gallery-wrapper" elevation="2">

      <div class="media-scroll-container">
        <v-card
          v-for="(item, index) in slider"
          :key="index"
          class="media-card"
          elevation="3"
          @click="fullScreenImage(item.file_url, item.type)"
        >
          <v-img
            v-if="item.type === 'image'"
            :src="item.file_url"
            class="media-image"
            contain
            height="200"
          />
          <div v-else class="video-wrapper">
            <video class="media-image" height="200" muted>
              <source :src="item.file_url" />
            </video>
            <img
              src="@/assets/play.png"
              alt="Play"
              class="play-button"
            />
          </div>
          <v-card-subtitle class="media-title">
            {{ item.title || 'Untitled' }}
          </v-card-subtitle>
        </v-card>
      </div>

      <full-screen-image-viewer
        :imageViewer="imageViewer"
        v-if="imageViewer.flag"
      />
    </v-container>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
// import VueVideoThumbnail from "vue-video-thumbnail";
import { GET_GALLEY_ASSETS } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "WizfitGallery",
  components: {
    // VideoPlayer: () => import("@/components/VideoPlayer/VideoPlayer"),
    FullScreenImageViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
    // VueVideoThumbnail,
  },
  data() {
    return {
      slider: [],
      imageViewer: {
        src: "",
        flag: false,
        type: "",
      },
      img: "",
      upHere: false,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      console.log(this.getCampaign.campaign_assests_list, "campaign assets");
      let asset_list = [];
      this.getCampaign.campaign_assests_list.forEach((element) => {
        let arr = {
          file: element.file,
          file_url: element.file_url,
          id: element.id,
          title: element.title,
          type: element.type,
          upHere: false,
        };
        asset_list.push(arr);
      });
      return asset_list;
    },
    getGallery() {
      let asset_list = [];
      if (this.getCampaign.type.type === "student") {
        asset_list = this.getCampaign.teacher_assests_list.concat(
          this.getCampaign.student_assests_list
        );
      } else if (this.getCampaign.type.type === "teacher") {
        asset_list = this.getCampaign.teacher_assests_list;
      }
      return asset_list;
    },
    arrowCondition() {
      if (this.slider.length > 3) {
        return true;
      } else if (this.$vuetify.breakpoint.xs && this.slider.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
  },
  methods: {
    loadAssets() {
      this.slider = this.getCampaignDetail;
      if (this.getCampaign.type.type === "student") {
        this.slider = this.slider.concat(this.getGallery);
      } else if (this.getCampaign.type.type === "teacher") {
        this.slider = this.slider.concat(this.getGallery);
      }
    },
    fullScreenImage(url, type) {
      this.imageViewer.src = url;
      this.imageViewer.type = type;
      this.imageViewer.flag = true;
    },
    mapAssets(asset1, asset2) {
      if (this.getCampaign.type.type === "student") {
        this.slider = asset1;
      } else {
        this.slider = asset2;
      }
    },
    getGalleryAssets() {
      if (!this.getCampaign || !this.getCampaign.campaign_detail || !this.getCampaign.student_detail) {
    console.warn("Campaign data is not yet available.");
    return;
  }

      const successHandler = (res) => {
        // console.log(res.data, "........");
        let asset1 = [];
        let asset2 = [];
        if (this.getCampaign.type.type === "student") {
          asset1 = res.data.campaign_assests_list.concat(
            res.data.teacher_assests_list,
            res.data.student_assests_list,
            res.data.approved_student_assests_list
          );
        } else {
          asset2 = res.data.campaign_assests_list.concat(
            res.data.teacher_assests_list,
            res.data.approved_student_assests_list
          );
        }
        this.mapAssets(asset1, asset2);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      formData["student_id"] = this.getCampaign.student_detail.id;
      // if (!this.getCampaign.type) {
      //   // console.log("in campaign level");
      //   if (this.getCampaign.campaign_detail.campaign_type === "school") {
      //     // console.log(this.getCampaign.school_detail.id);
      //     formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      //     formData["school_id"] = this.getCampaign.school_detail.id;
      //   } else {
      //     // console.log(this.getCampaign.district_detail.id);
      //     formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      //     formData["district_id"] = this.getCampaign.district_detail.id;
      //   }
      // } else if (this.getCampaign.type.type === "school") {
      //   // console.log(this.getCampaign.school_detail.id);
      //   formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      //   formData["school_id"] = this.getCampaign.school_detail.id;
      // } else if (this.getCampaign.type.type === "teacher") {
      //   // console.log("teacher");
      //   formData["teacher_id"] = this.getCampaign.teacher_detail.id;
      // } else {
      //   formData["student_id"] = this.getCampaign.student_detail.id;
      // }
      Axios.request_GET(
        GET_GALLEY_ASSETS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    // this.loadAssets();
    // console.log(this.getCampaign, "gallery campaign...");
    this.getGalleryAssets();
    // console.log(this.getStudentGallery, "student assets");
  },
  watch: {
  getCampaign: {
    handler(newCampaign) {
      if (newCampaign && newCampaign.campaign_detail && newCampaign.student_detail) {
        this.getGalleryAssets(); // Fetch gallery data only when campaign data is ready
      }
    },
    deep: true,
    immediate: true, // Run immediately if data is already available
  }
},
};
</script>
<style scoped>
.gallery-section {
  background-color: #f9f9f9;
  padding: 40px 0;
}

.gallery-wrapper {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
}

.heading {
  font-size: 26px;
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  color: #2c1963;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  place-content: center;
}

.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-left: 15px;
}

.media-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding-bottom: 10px;
  scroll-behavior: smooth;
}

.media-card {
  min-width: 240px;
  max-width: 280px;
  border-radius: 14px;
  flex-shrink: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  
}

.media-card:hover {
  transform: translateY(-4px);
}

.media-image {
  width: 100%;
  object-fit: contain;
  /* background-color: #2c1963; */
}

.video-wrapper {
  position: relative;
  height: 200px;
  background-color: #000;
  overflow: hidden;
  border-radius: 8px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.media-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: center;
  padding: 8px;
}

/* Mobile Responsive */
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }

  .hLine {
    width: 30px;
    height: 3px;
  }

  .media-card {
    min-width: 180px;
    max-width: 220px;
  }

  .media-title {
    font-size: 13px;
  }

  .play-button {
    width: 40px;
    height: 40px;
  }
}


</style>
